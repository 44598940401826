@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  :root {
    --background: #FFFFFF;
    --primary: #6D6AFF;
    --primary-dark: #2A295F;
    --text: #2A295F;
    --gray: #ABABAB;
    --card: #F8F8F8;
    --red: #FF005C;
    --white: #FFFFFF;
    --black: #1E1E1F;
    --overlay: #000;
    --alert-color: #1E1E1F;
    --alert-text: #FFFFFF;
    --green: #00D95F;
    --primary-light: #C9C5F7;
    --green-light: #DCF7C5;
    --blue-light: #A7D3FF;
    --blue: #3497F9;
    --header-background: #FFFFFF;
  }

  .dark {
    --background: #1E1E1F;
    --primary: #6D6AFF;
    --primary-dark: #2A295F;
    --text: #FFFFFF;
    --gray: #ABABAB;
    --card: #161616;
    --red: #FF005C;
    --white: #FFFFFF;
    --black: #1E1E1F;
    --overlay: #000;
    --alert-color: #FFFFFF;
    --alert-text: #1E1E1F;
    --green: #00D95F;
    --primary-light: #C9C5F7;
    --green-light: #DCF7C5;
    --blue-light: #A7D3FF;
    --blue: #3497F9;
    --header-background: #161616;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @apply dark;
  }
}

body {
  background-color: var(--background);
  color: var(--text);
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}